/* Spinner.css */
.spinner {
  width: 50px;
  height: 50px;
  margin: 100px auto;
  /* Center the spinner */
  border-radius: 50%;
  background-color: #3498db;
  /* Blue color */
  animation: throb 1.5s infinite ease-in-out;
}
.spinner-image {
  width: 50px;
  height: 50px;
  margin: 100px auto;
  /* Center the spinner */
  display: block;
  animation: throbAndSpin 1.5s infinite ease-in-out;
}

@keyframes throb {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(2);
  }
}

@keyframes throbAndSpin {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1.5) rotate(180deg);
    /* Throb and spin 180 degrees */
  }

  100% {
    transform: scale(1) rotate(360deg);
    /* Return to original size and complete spin */
  }
}