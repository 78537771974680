.card {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 15px 10px 10px rgba(1, 29, 244, 0.1);
  width: 700px;
  padding: 20px;
  margin: 20px;
  text-align: center;
}

.card-number {
  font-size: 48px;
  font-weight: bold;
  color: #3f6ce0;
}

.card-heading {
  font-size: 24px;
  margin-top: 10px;
  color: #333;
  white-space: pre-line;
}

.card-iframe {
  margin: 20px 0;
  border: none;
  width: 100%;
  height: 400px;
  border-radius: 8px;
}

.card-list {
  text-align: left;
  padding-left: 20px;
}

.card-list li {
  margin-bottom: 10px;
  color: #555;
}