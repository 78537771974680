.button {
  flex: 0 1 calc(50% - 2%);
    /* Take up half of the container width minus the gap */
    box-sizing: border-box;
    /* padding: 10px; */
    /* background-color: #f0f0f0; */
    /* border: 1px solid #ccc; */
    align-items: center;
    display: flex;
    justify-content: center;
}
.buttonContainer {
  display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding-top: 3%;
    /* overflow-y: scroll; */
    /* Space between items */
}